export const MESSAGE_ACTION = {
  edit: "edit",
  quote: "quote",
  delete: "delete",
  pin: "delete",
  forward: "forward",
  reply: "reply",
  copy: "copy",
  select: "select",
};

export const MESSAGE_INPUT = {
  main: {
    id: "main-message",
    input: "main-message-input",
    placeholder: "main-message-placeholder",
    emoji: "main-message-emoji",
  },
  sendMedia: {
    id: "send-media",
    input: "send-media-input",
    placeholder: "send-media-placeholder",
    emoji: "send-media-emoji",
  },
  sendFile: {
    id: "send-file",
    input: "send-file-input",
    placeholder: "send-file-placeholder",
    emoji: "send-file-emoji",
  },
};
