import { stringToDom } from "@common/utils/common";
import { MESSAGE_INPUT } from "@domain/constant/message-constant";
import { getConversationNameFromUsers } from "@domain/helpers/conversation-helper";
import { getLastName } from "@domain/helpers/user-helper";
import { IMessage, MessageActions } from "@domain/interfaces/message-interface";
import { IUser } from "@domain/interfaces/user-interface";
import { defineStore } from "pinia";
import { useAccountStore } from "./account-store";
import { useAppStateStore } from "./app-store";

type RightSidebarSlot = "groupInfo" | "userInfo" | "setting" | undefined;

export const useChatDialogStateStore = defineStore("chat-dialog-state", {
  state: () => ({
    userContactModal: {
      visible: false,
      action: undefined as "addMember" | undefined,
    },
    contact: {
      selectedItems: [] as IUser[],
    },
    createGroup: {
      groupName: "",
      avatar: undefined,
      groupNameRef: "",
    },
    reactionMessageModalVisible: false,
    messageMenu: {
      selectedText: "",
      quoteText: "",
      activeMessage: {} as IMessage,
      selectedMessage: undefined as IMessage | undefined,
      messageAction: "" as MessageActions | undefined,
    },
    rightSidebar: {
      visible: false,
      slot: undefined as RightSidebarSlot,
    },
    mainInputState: {
      clipboardDataText: "",
    },
    messageInputState: {
      selectedRange: undefined as Range | undefined,
    },
    upload: {
      media: {
        fileList: [] as File[],
        visible: false,
      },
      files: {
        fileList: [] as File[],
        visible: false,
      },
    },
    toolbar: {
      visible: false,
      isBold: false,
      isUnderline: false,
      isItalic: false,
      isStrikethrough: false,
      position: { x: 0, y: 0 },
    },
  }),
  actions: {
    openContactModal (action: "addMember") {
      this.userContactModal = { visible: true, action };
      this.contact.selectedItems = [];
    },
    closeContactModal () {
      this.userContactModal = { visible: false, action: undefined };
      this.contact.selectedItems = [];
    },
    closeMessageAction () {
      this.messageMenu.messageAction = undefined;
      this.messageMenu.selectedText = "";
      this.messageMenu.activeMessage = {} as IMessage;
      this.messageMenu.selectedMessage = undefined;
    },
    openMessageAction (action: MessageActions) {
      this.messageMenu.messageAction = action;
      this.messageMenu.selectedMessage = this.messageMenu.activeMessage;

      if (action === "edit") {
        if (this.messageMenu.selectedMessage.parseMode === "html") {
          this.updateInputContent({
            id: MESSAGE_INPUT.main.input,
            content: stringToDom(this.messageMenu.selectedMessage.content),
            mode: "replaceChild",
          });
        } else {
          this.updateInputContent({
            id: MESSAGE_INPUT.main.input,
            content: document.createTextNode(this.messageMenu.selectedMessage.content),
            mode: "replace",
          });
        }
      } else {
        this.updateInputContent({
          id: MESSAGE_INPUT.main.input,
          content: "",
          mode: "replace",
        });
      }

      if (action === "quote") {
        this.messageMenu.quoteText = this.messageMenu.selectedText;
      }
    },
    openReactionMessageModal () {
      this.reactionMessageModalVisible = true;
    },
    closeReactionMessageModal () {
      this.reactionMessageModalVisible = false;
    },
    openRightSidebar (slot: RightSidebarSlot) {
      this.rightSidebar.slot = slot;
      this.rightSidebar.visible = true;
    },
    closeRightSidebar () {
      this.rightSidebar.slot = undefined;
      this.rightSidebar.visible = false;
    },
    openCreateGroup () {
      useAppStateStore().setMiddleColumnSlot("createGroup");
      const ownerName = getLastName(useAccountStore().loginUser.name);
      this.createGroup.groupName = getConversationNameFromUsers(this.contact.selectedItems);
      this.createGroup.groupName = this.createGroup.groupName.trim().length > 0 ? `${ownerName}, ${this.createGroup.groupName}` : ownerName;

      setTimeout(() => {
        document.getElementById("input-group-name")?.focus();
      }, 200);
    },
    closeCreateGroup () {
      useAppStateStore().resetMiddleColumn();
      this.contact.selectedItems = [];
    },
    hideToolbar () {
      this.toolbar.visible = false;
      this.messageInputState.selectedRange = undefined;
      this.toolbar.isBold = false;
      this.toolbar.isItalic = false;
      this.toolbar.isUnderline = false;
      this.toolbar.isStrikethrough = false;
    },
    clearSelection () {
      const selection = window.getSelection();

      if (selection) {
        selection.removeAllRanges();
      }
      this.messageInputState.selectedRange = undefined;
    },
    focusInput (input: HTMLElement | undefined | null) {
      if (!input) return;
      // Set start position of range
      const nodeLength = input.childNodes.length;

      if (nodeLength > 0) {
        // Creates range object
        const range = document.createRange();

        // Creates object for selection
        const selection = window.getSelection();
        range.setStartAfter(input.childNodes[nodeLength - 1]);
        range.collapse(true);
        selection?.removeAllRanges();
        selection?.addRange(range);
      } else {
        input.focus();
      }
    },
    updateInputContent (data: {
      id: string;
      content: any;
      mode: "append" | "replace" | "appendChild" | "replaceChild";
      focusInput?: HTMLElement | undefined | null;
    }) {
      const input = document.getElementById(data.id);
      const placeholder = document.getElementById(data.id.replace("-input", "-placeholder"));
      // console.log(`${data.id}`, data.id.replace("-input", "-placeholder"), input);

      if (!input) return "";

      if (data.content) {
        if (data.mode === "replace") {
          while (input.firstChild) {
            input.removeChild(input.firstChild);
          }

          input.append(data.content);
        } else if (data.mode === "append") {
          input.append(data.content);
        } else if (data.mode === "appendChild") {
          Array.from(data.content).forEach((node: any) => {
            input.appendChild(node);
          });
        } else if (data.mode === "replaceChild") {
          while (input.firstChild) {
            input.removeChild(input.firstChild);
          }

          Array.from(data.content).forEach((node: any) => {
            input.appendChild(node);
          });
        }

        if (placeholder) {
          placeholder.style.display = "none";
        }
      } else {
        while (input.firstChild) {
          input.removeChild(input.firstChild);
        }

        // input.append(document.createTextNode(""));

        if (placeholder) {
          placeholder.style.display = "flex";
        }
      }

      if (data.focusInput) {
        this.focusInput(data.focusInput);
      } else {
        this.focusInput(input);
      }
    },
    getInputContent (id: string) {
      const input = document.getElementById(id);

      if (input?.innerHTML === "<br>") return "";

      return input?.innerHTML || "";
    },
    updateEmojiVisible (id: string | undefined, display?: "none" | "block") {
      if (!id) return;
      const emoji = document.getElementById(id);
      // console.log("updateEmojiVisible", id, emoji);

      if (emoji) {
        if (display) {
          emoji.style.display = display;

          return;
        }

        if (emoji.style.display === "none") {
          emoji.style.display = "block";
        } else if (emoji.style.display === "block") {
          emoji.style.display = "none";
        }
      }
    },
  },
});
