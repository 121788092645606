import { scrollToMessage } from "@domain/helpers/message-helper";
import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { useConversationStore } from "@presentation/store/conversation-store";
import { useMessageStore } from "@presentation/store/message-store";
import { Socket } from "socket.io-client";
import { handleDebug } from "../helper";

export default function onNewMessage (socket: Socket<ServerToClientEvents, ClientToServerEvents>) {
  socket.on("newMessage", (response) => {
    handleDebug("newMessage", response.data);
    const messageStore = useMessageStore();
    const conversationStore = useConversationStore();

    conversationStore.updateLocalConversationList(response.data.conversation, response.data.message);

    // if new message is in selected conversation
    if (response.data.message.conversationId === conversationStore.selectedConversation.id) {
      messageStore.updateLocalSelectedConversationMessage(response.data.message);

      // if selected conversation is wheel up
      if (messageStore.wheel.up) {
        conversationStore.updateLocalSelectedConversation({ unreadCount: response.data.conversation.unreadCount });
        messageStore.updateLocalUnreadCount(messageStore.unreadCount + 1);

        return;
      }
      scrollToMessage(response.data.message.id);
      conversationStore.updateLocalSelectedConversation({ unreadCount: 0 });
      conversationStore.updateConversationDetails({
        lastSeenAt: conversationStore.selectedConversation.lastMessageInfo.createdAt,
        lastSeenMessageId: conversationStore.selectedConversation.lastMessageInfo.id,
        conversationId: conversationStore.selectedConversation.id,
        readMessageCount: conversationStore.selectedConversation.unreadCount,
        readAll: true,
      });

      return;
    }
    // if new message is NOT in selected conversation
    messageStore.updateLocalUnreadCount(messageStore.unreadCount + 1);
  });
}
