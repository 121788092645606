import repository from "@config/repository";
import MessageRepository from "@domain/repositories/message-repository";

import { EditMessageBody } from "@domain/interfaces/message-interface";

export default function editMessageUseCase (id: string, body: EditMessageBody) {
  const messageRepository = repository.get<MessageRepository>(MessageRepository);

  return messageRepository.editMessage(id, {
    content: body.content,
    mention: body.mention,
    parseMode: body.parseMode,
  });
}
